import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SolutionArticle from "../components/solutionArticle"

const IndexPage = () => {

    const solutionData = require('../../data/solutions.json');

    return <Layout>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="mx-auto h-full py-16 max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-4xl">Solutions</h1>
                    <p class="mt-4 text-gray-600">
                        There are so many things that you can do and build with the Irishman Cloud. To show you what's possible, we've assembled a collection of projects that can be built using our tools.
                    </p>
                </div>
            </div>
        </section>
        <section>
            <div class="mx-auto max-w-screen-xl px-4 pb-16 sm:px-6 lg:px-8">
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {
                        solutionData.map((item) => SolutionArticle({ ...item, href: `/solutions/${item.slug}` }))
                    }
                </div>
            </div>
        </section>
    </Layout>
}

export const Head = () => <Seo title="Solutions" />

export default IndexPage
