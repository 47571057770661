import * as React from "react"

export default function SolutionArticle({ name, description, date, slug, image }) {

    return (
        <a href={`/solutions/${slug}`}>
            <article class="overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                <img
                    alt="Office"
                    src={image}
                    class="h-56 w-full object-cover"
                />
                <div class="bg-white p-4 sm:p-6">
                    <time datetime={date} class="block text-xs text-gray-500">
                        {date}
                    </time>

                    <a href="#">
                        <h3 class="mt-0.5 text-lg text-gray-900">
                            {name}
                        </h3>
                    </a>

                    <p class="mt-2 text-sm leading-relaxed text-gray-500 line-clamp-3">
                        {description}
                    </p>
                </div>
            </article>
        </a>
    );

}